import React from 'react';

function AboutUs(props) {
    return (
        <div>
            <p className='about-us'>
                WingSharing.org
                <br/>
                info@wingsharing.org
                <br/>
                <br/>
                DIFRNT COMPOUND
                <br/>
                Sourethweg 9a
                <br/>
                6422 PC
                <br/>
                Heerlen
                <br/>
                Netherlands
            </p>
        </div>
    );
}

export default AboutUs;